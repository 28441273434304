.menu__menu-option {
  padding: 0.9rem !important;
  color: $sec-gray-guay !important;
  transition: 0.5s !important;
  cursor: pointer;
}
.menu__menu-option span.show-1,
.menu__menu-option span.show-2,
.menu__menu-option span.show-3,
.menu__menu-option span.show-4 {
  position: relative;
  animation-name: showOptions;
}
.menu__menu-option span.show-1 {
  animation-duration: .8s;
}
.menu__menu-option span.show-2 {
  animation-duration: 1s;
}
.menu__menu-option span.show-3 {
  animation-duration: 1.2s;
}
.menu__menu-option span.show-4 {
  animation-duration: 1.4s;
}
.menu__menu-option:hover {
  background-color: rgba(21, 37, 60, 0.75);
  border-right: 5px solid $sec-blue-guay;
  color: $white !important;
}
.menu__menu-option-selected {
  padding: 1rem !important;
  background-color: rgba(21, 37, 60, 0.50);
  border-right: 5px solid $sec-blue-guay;
  color: $white !important;
}
@keyframes showOptions {
  0% {
    left: 0px;
    top: 0px;
    opacity: 0;
  }
  50% {
    left: 25px;
    top: 0px;
    opacity: 0;
  }
  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
  }
}
