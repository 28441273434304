.presence__show-container {
  position: relative;
  animation-name: showContainer;
}
.presence__show-container.container-1 {
  animation-duration: 1s;
}
.presence__show-container.container-2 {
  animation-duration: 2s;
}
.presence__show-container.container-3 {
  animation-duration: 3s;
}
@keyframes showContainer {
  0% {
    left: 0px;
    top: 0px;
    opacity: 0;
  }
  50% {
    left: 0px;
    top: 100px;
    opacity: 0;
  }
  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
  }
}

.presence__text-blue {
  color: $blue-guay;
}

.tab-style-1 {
  background-color: $white-translucid-verylow !important;
  color: $sec-blue-guay !important;
  border-top-left-radius: 1.25rem !important;
  border-top-right-radius: 1.25rem !important;
}

.tab-style-2 {
  background-color: $white-translucid-verylow !important;
  color: $pink-guay !important;
  text-decoration: underline;
  font-weight: bold;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  border-top-left-radius: 1.25rem !important;
  border-top-right-radius: 1.25rem !important;
}

.bg_modal_presence {
  background-color: $light-blue !important;
  border-radius: 15px !important;
}

.bg_modal_rounded {
  border-radius: 15px !important;
  border: 2px solid #b9bbc2 !important;
}