@media (min-width: 769px) {
    .login__container-centered {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
}

.login__logo-icons {
    height: 24px;
}

.login__text-decoration-none:hover {
    color: $pink-guay;
}