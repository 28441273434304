.reports__div_map_info {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    right: 20px;
    top: 65px;
    z-index: 1;
    overflow-y: auto;
    height: 255px;
    opacity: 0.90;
}

.reports__div_map_info::-webkit-scrollbar {
    width: 10px;
}

.reports__div_map_info::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
}

.reports__div_map_info::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 25px;
}

.reports__div_map_info::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.reports__cards-style {
    background-color: $blue-background;
    border-radius: 20px;
    border: 0px;
}

.reports__cards-style-yellow {
    background-color: $blue-background;
    border-radius: 20px;
    border: 0px;
}

.reports__cards-style-blue {
    background-color: $blue-background;
    border-radius: 20px;
    border: 0px;
}

.reports__cards-style-yellow {
    background-color: $yellow-background;
    border-radius: 10px;
    border: 0px;
}

.reports__cards-style-blue {
    background-color: $blueGreen-background;
    border-radius: 10px;
    border: 0px;
}

.reports__cards-style-green {
    background-color: $green-background;
    border-radius: 10px;
    border: 0px;
}

.reports__horizontalRule {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 1 !important;
    height: 3px !important;
}

.reports__btn-pink {
    background-color: #934171;
}

.reports__bringFront {
    z-index: 1;
}

.reports__loader-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 85vh;
    justify-content: center;
    width: 100%;
}