.flip-container {
    -webkit-perspective: 1000;
    margin: 0 auto 0 auto;
    width: 100%;
    height: auto;
    border-radius: 15px;
    cursor:pointer;
}
.flip-container:hover .flipper {
    -webkit-transform: rotateY(180deg);
    cursor:pointer;
}
.flip-container.hover .flipper {
    -webkit-transform: rotateY(180deg);
    cursor:pointer;
}
.front {
    width: 100%;
    height: auto;
    border-radius: 15px;
    -webkit-backface-visibility: hidden;
    position: relative;
    text-align: center;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: $sec-blue-gray-guay;
    color: white;
}
.back {
    width: 100%;
    height: auto;
    border-radius: 15px;
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: rotateY(180deg);
    text-align: center;
    color: $white;
    background-color: $blue-guay;
}
.flipper {
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    position: relative;
}
.option-card {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}
.option-card:hover {
    background-color: $white;
    color: $blue-guay;
    width: 40px;
    height: 40px;
    border-radius: 50px;
}
.pagination-option {
    color: $deg-blue-guay;
    border-radius: 50px;
}
.pagination-option-active {
    background-color: $deg-blue-guay;
    color: $white;
    border-radius: 50px;
}
.span-circle-market-selected {
    border-radius: 50px !important;
    width: 25px !important;
    height: 25px !important;
    text-align: center !important;
    color: $white;
}
.span-circle-market-unselected {
    border-radius: 50px !important;
    width: 25px !important;
    height: 25px !important;
    text-align: center !important;
    color: $dark;
}
.border-blue-guay {
    border: solid 1px $blue-guay
}
.border-pink-guay {
    border: solid 1px $pink-guay
}
.border-yellow-guay {
    border: solid 1px $yellow-guay
}

.bg-green-check.form-check-input:checked {
    background-color: $green-guay;
    border-color: $green-guay;
}