.bg-green-opacity {
    background-color:rgba($color: $green-guay, $alpha: 0.2);
}

.bg-cian-opacity {
    background-color:rgba($color: $blue-guay, $alpha: 0.2);
}

.bg-yellow-opacity {
    background-color:rgba($color: $yellow-guay, $alpha: 0.2);
}