// General html
* {
    font-family: "Quicksand", sans-serif;
}
html {
    min-height: 100%;
    position: relative;
}
body {
    background: url("../../assets/elementos_patrocinios-12.png") no-repeat fixed center;
    background-size: 100% 100%; /* Resize the background image to cover the entire container */
}
// Text colors
.text-green {
    color: $green-guay;
}
.text-yellow {
    color: $yellow-guay;
}
.text-pink {
    color: $pink-guay;
}
.text-cian {
    color: $blue-guay;
}
.text-blue-sec {
    color: $sec-blue-guay;
}
.text-base {
    color: $sec-blue-gray-guay;
}
.text-gray {
    color: $sec-gray-guay;
}
.text-gray-lighten {
    color: lighten($sec-gray-guay, 45%) !important;
}
// Background styles
.bg-image-one {
    background-image: url("../../assets/elementos_patrocinios-11-11.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}
.bg-image-two {
    background-image: url("../../assets/elementos_patrocinios-11_b.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}
.bg-blue-navy {
    background-color: $ad-blue-navy;
}
.bg-blue-guay {
    background-color: $blue-guay;
}
.bg-blue-light {
    background-color: $light-blue;
}
.bg-blue-white {
    background-color: $blue-white;
}
.bg-white_translucid {
    background-color: $white-translucid-low;
}
.bg-white_translucid-verylow {
    background-color: $white-translucid-verylow;
}
.bg-gray {
    background-color: #f0f3fc !important;
}
.bg-pink-guay {
    background-color: $pink-guay;
}
.bg-yellow-guay {
    background-color: $yellow-guay;
}
// Input
.textarea-unresize {
    resize: none;
}
.input-icon-right input {
    border-right: none;
}
.input-icon-right span {
    background-color: transparent;
    border-left: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.form-control-gray {
    background-color: #f0f3fc !important;
    border: none !important;
}
// Dropdown
.dropdown-nav {
    width: 35px;
    height: 35px;
    background-color: $sec-blue-guay;
    color: $white;
    border-radius: 100px;
    border: none;
    font-size: 0.75rem;
}
.dropdown-menu-nav {
    width: 40% !important;
}
.dropdown-item-div {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
// Buttons
.btn-pink-guay {
    background-color: $pink-guay !important;
    border-color: $pink-guay !important;
}
// Container
.container-vh-50 {
    max-height: 50vh;
    overflow: auto;
}
.container-vh-50::-webkit-scrollbar {
    -webkit-appearance: none;
}
.container-vh-50::-webkit-scrollbar:vertical {
    width: 10px;
}
.container-vh-50::-webkit-scrollbar-button:increment, .container-vh-50::-webkit-scrollbar-button {
    display: none;
}
.container-vh-50::-webkit-scrollbar-thumb {
    background-color: silver;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}
.container-vh-50::-webkit-scrollbar-track {
    border-radius: 10px;
}
// Table
.generic-table tbody {
    border-top: none !important;
}
.generic-table .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #f0f3fc;
    color: #71768d;;
}
.generic-table .table>:not(caption)>*>* {
    background-color: var(--bs-table-bg);
}
// Extra
.box-shadow {
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
}
.box-shadow-2 {
    box-shadow: 1px 5px 10px 5px rgba(0, 0, 0, 0.2);
}
.blurryImg {
    image-rendering: -webkit-optimize-contrast;
}
.span-circle {
    width: 35px !important;
    height: 35px !important;
    border-radius: 100px !important;
}
.pointer {
    cursor: pointer;
}
.img-150 {
    width: 150px;
}
.img-250 {
    width: 250px;
}
.fs-7 {
    font-size: 0.85rem;
}
.pull-up {
    transition: all 0.25s ease !important;
}
.pull-up:hover {
    transform: translateY(-4px) scale(1.02);
    box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
    z-index: 999;
}
.w-15 {
    width: 15% !important;
}
.w-30 {
    width: 30% !important;
}