//Colors
$dark: #000;
$white: #fff;
$light-blue: #f3f2f8;
$blue-white: #f0f3fc;

//principales
$green-guay: #9cb537;
$pink-guay: #af2f72;
$blue-guay: #2188b4;
$yellow-guay: #dfa444;

//secundarios
$sec-blue-guay: #15253c;
$sec-gray-guay: #5b5b5b;
$sec-blue-gray-guay: #71768d;
$sec-white-gray-guay: #efeff6;

//en degradado
$deg-green-guay: #6fbb00;
$deg-blue-guay: #0095c2;
$deg-yellow-guay: #dfa444;
$deg-pink-guay: #ae2f72;

//adicionales
$ad-purple: #5f5fca;
$ad-blue-navy: #00001D;
$white-translucid-verylow: rgba(255, 255, 255, 0.9);
$white-translucid-low: rgba(255, 255, 255, 0.7);

//reportes
$blue-background: #eef1fa;
$yellow-background: #ce954e;
$blueGreen-background: #6389a0;
$green-background: #8a9f44;